/* NavBot.css */

.navbot-container {
  background-color: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 10px;
  z-index: 1030;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbot-container .nav-link {
  color: var(--normal-text-color) !important;
  margin-right: 15px;
  white-space: nowrap;
}

.navbot-container .nav-link:hover {
  color: var(--primary-color) !important;
  transition: color 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .navbot-container {
    display: none;
  }
}
