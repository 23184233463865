.header-navbar {
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.header-navbar .logo {
    width: 100px;
    height: auto;
}

.header-navbar .navbar-nav .nav-link {
    font-size: 1rem;
    margin-right: 15px;
    color: #000 !important;
}

.header-navbar .navbar-nav .nav-link:hover {
    color: #ff4500 !important;
    transition: color 0.3s ease-in-out;
}

.header-navbar .notification-icon {
    margin-right: 20px;
}

.header-navbar .avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
}