.tac-avatar-image {
    border: 1px solid var(--normal-text-color);
    transition: all 0.3s ease;
}

.tac-avatar-image:hover {
    border-color: var(--primary-color);
    cursor: pointer;
}

/* AvatarStyle.css */
.tac-avatar-container {
    position: relative;
    display: inline-block;
}
.tac-dropdown-url {
    text-decoration: none;
    border-bottom: 1px solid var(--divider-color);
    color: var(--normal-text-color);
}

.tac-dropdown-url:hover {
    background-color: black;
}

.tac-dropdown-menu {
    display: none;
    position: absolute;
    background-color: var(--white-color);
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 2000;
    top: 100%;
    border-radius: 15px;
    right: 0;
}

.tac-avatar-container.active .tac-dropdown-menu {
    display: block;
    animation: slideIn 0.3s ease-in-out;
}

.tac-dropdown-menu .tac-dropdown-item {
    color: var(--normal-text-color);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}