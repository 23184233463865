/* .tac-search-form{
    width: 80%;
    background-color: var(--background-color);
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    color: var(--normal-text-color);
    border: 0px solid transparent;
    transition: all 0.3s ease;
    font-size: var(--search-text-size);
}
.tac-search-form:focus{
    width: 100%;
    background-color: var(--white-color);
    color: var(--normal-text-color);
    border-radius: 0px;
  }
.tac-search-results {
    margin: auto;
    position: absolute;
    background-color: var(--white-color);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    box-sizing: border-box;
    z-index: 1000;
    overflow: hidden;
    transition: all 3s ease ;
    top: 70%;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid black;
    text-align: start !important;
    display: none;
}
.tac-search-results.active{
    display: block;
    animation: slideIn 0.3s ease-in;
}
.tac-search-result-item {
    padding: 10px;
    border-bottom: 1px solid var(--divider-color);
    cursor: pointer;
}
.tac-search-result-item:last-child {
    border-bottom: none;
}
.tac-search-result-item:hover {
    background-color: var(--item-hover-background);
}
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} */
/* Style cho form tìm kiếm */
input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }
.tac-search-form {
    text-align: center;
    width: 60%;
    padding: 10px 20px;
    background: var(--background-color);
    border: none;
    border-radius: 15px;
    outline: none; 
    transition: width 0.3s ease, border-radius 0.3s ease, border 0.3s ease;
}
  
.tac-search-form:focus {
    width: 100%;
    border-radius: 15px 15px 0 0;
    border-bottom: 0;
    border: 1px solid #ccc;
    background-color: #fff;
}
.tac-search-results {
  display: none; 
  position: absolute;
  background-color: #fff;
  border-top: none;
  border: 1px solid #ccc;
  border-radius: 0 0 15px 15px;
  z-index: 1200;
  max-height: 200px; 
  overflow-y: auto; 
}
  
.tac-search-results.active {
  display: block;
  animation: slideIn 0.3s ease-in;
}
.tac-search-result-item {
  text-align: start;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
  
.tac-search-result-item:last-child {
  border-bottom: none;
}
  
.tac-search-result-item:hover {
  background-color: var(--item-hover-background); 
}
  