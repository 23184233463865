@import 'bootstrap-icons/font/bootstrap-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Madimi+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    /*Mã màu đang dùng*/
    --primary-color: #02577a;
    --secondary-color: #47B7F5;
    --background-color: #E6E6E6;
    --danger-color: #C7372F;
    --success-color: #73D06F;
    --white-color: #fff;
    /*Mã màu chữ  */
    --normal-text-color: #272727;
    --title-text-color: black;
    --time-text-color: #6c757d;
    /*Text-size*/
    --normal-text-size: 1rem;
    --title-text-size: 13px;
    --search-text-size: 18px;
    --notification-size-icon: 1.3rem;
    --time-text-size: 0.8rem;
    /* Mã màu khác */
    --divider-color: #eeeeee;
    --item-hover-background: #f8f8f8;
    /* Key frame */
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.375rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 0.375rem;
}

::-webkit-scrollbar-track:hover {
    background-color: #e1e1e1;
    /* Màu của nền thanh cuộn khi hover */
}

* {
    font-family: "Lora", serif;
    font-weight: 550;
}

body {
    background-color: var(--background-color);
}

.tac-btn {
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    user-select: none;
    background-color: var(--background-color);
}

.tac-btn:active {
    scale: 0.9;
}

.tac-btn.tac-btn-primary {
    background-color: transparent;
    color: var(--normal-text-color);
}

.tac-btn.tac-btn-secondary {
    background-color: transparent;
    color: var(--normal-text-color);
}

.tac-btn.tac-btn-danger {
    background-color: transparent;
    color: var(--danger-color);
}

.tac-btn.tac-btn-secondary:hover {
    background-color: var(--background-color);
}

.tac-btn.tac-btn-danger:hover {
    background-color: var(--danger-color);
    color: white;
}

.tac-btn-fill {
    padding: 10px;
    border-radius: 0 0.375rem 0.375rem 0 !important;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    user-select: none;
}

.tac-btn-fill.tac-btn-primary {
    background-color: var(--primary-color);
    color: white;
}

.tac-btn-fill.tac-btn.tac-btn-danger {
    background-color: var(--danger-color);
    color: white;
}

.tac-section {
    border-radius: 0.375rem;
    padding: 25px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    user-select: none;
}

.tac-background-white {
    background-color: #fff;
}

.tac-input {
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--background-color);
    padding: 10px 20px;
    border-radius: 0.375rem;
    color: var(--normal-text-color);
}

.form-control.tac-input:hover {
    border: none;
}

textarea.tac-input {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 0.375rem;
    background-color: var(--background-color);
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
    resize: none;
    overflow: hidden;
    min-height: 50px;
    max-height: 100px;
    color: var(--normal-text-color);
}

.tac-normal-text {
    color: var(--normal-text-color);
}

.tac-badge-primary {
    font-size: 0.9rem;
    background-color: var(--primary-color);
    padding: 10px;
    border-radius: 15px;
    color: white;
}

.tac-badge-secondary {
    font-size: 0.9rem;
    background-color: var(--secondary-color);
    padding: 10px;
    border-radius: 15px;
    color: white;
}

.tac-badge-danger {
    font-size: 0.9rem;
    background-color: var(--danger-color);
    padding: 10px;
    border-radius: 15px;
    color: white;
}

.tac-badge-success {
    font-size: 0.9rem;
    background-color: var(--success-color);
    padding: 10px;
    border-radius: 15px;
    color: white;
}

.tac-title-profile {
    color: black;
    font-size: 17px;
    font-weight: bold;
    line-height: normal;
}

.tac-profile-text {
    font-size: var(--normal-text-size);
    color: var(--normal-text-color)
}

.tac-image-following {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.tac-following-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tac-btn-primary {
    background-color: var(--background-color);
    border: none;
    padding: 10px;
    border-radius: 15px;
    color: var(--normal-text-color);
    transition: background-color 0.3s, color 0.3s;
}

.tac-btn-primary:hover {
    background-color: var(--secondary-color);
    color: white;
}

.tac-link {
    text-decoration: none;
    font-size: 1rem;
    color: var(--normal-text-color);
    transition: color 0.3s;
    font-weight: bold;
    cursor: pointer;
}

.tac-link:active {
    scale: (0.9);
}

.tac-link.tac-font-normal {
    font-weight: 450 !important;
}

.tac-link:hover {
    color: var(--primary-color);
}

.tac-link>i.bi-trash3-fill:hover {
    color: var(--danger-color);
}

.tac-title-article {
    font-weight: bold;
    text-align: justify;
}

.tac-text-detail {
    font-weight: bold;
    font-size: 0.9rem;
}

#tac-main-article {
    text-align: justify;
    white-space: pre-wrap;
    word-wrap: break-word;

}

#tac-main-article img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.tac-thumb {
    text-align: center;
    max-height: 200px;
    max-width: 200px;
}

.tac-image-post {
    width: 100%;
    max-height: 400px;
    object-fit: fill;
}

.page-link {
    user-select: none;
    color: var(--primary-color);
    cursor: pointer;
}

.table img.tac-thumb {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

button.tac-link {
    background: none;
    border: none;
}

.modal-content {
    border-radius: 0px;
}

.modal-header .btn-close {
    font-size: 12px;
}