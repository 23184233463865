.tac-notification-bell {
    font-size: var(--notification-size-icon);
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
    color: var(--normal-text-color);
}
.tac-notification-bell:hover{
    color: var(--primary-color);
    transform: scale(1.1);
}
.tac-notification-bell:active {
    color: var(--primary-color);
    transform: scale(1.1);
}
.tac-notification-dropdown {
    position: absolute;
    right: 0;
    display: none;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); 
    background-color: var(--white-color);
    padding: 15px;
    width: 400px;
    border: none;
    z-index: 2000;
    margin-top: 12px;
    border-radius: 15px;
}
.tac-notification-bell, .badge {
    -webkit-user-select: none;
    -moz-user-select: none;   
    -ms-user-select: none;    
    user-select: none;    
}
.tac-notification-dropdown.active {
    display: block;
    animation: slideIn 0.3s ease-in;
}
.tac-notification-item {
    display: block;
    padding: 10px;
    border-bottom: 1px solid var(--divider-color);
    color: var(--normal-text-color);
    text-decoration: none; 
    transition: background-color 0.3s ease; 
}
.tac-notification-item:hover {
    background-color: var(--item-hover-background); /* Màu nền khi hover */
}
.tac-title-notification {
    font-weight: bold; /* Đặt tiêu đề thông báo là in đậm */
    color: var(--title-text-color);
}
.tac-time-notification {
    font-size: var(--time-text-size);
    color: var(--time-text-color);
}